<template>
  <v-container class="px-6">
    <!-- Greeting -->
    <v-row>
      <v-col
        cols="auto"
        class="my-0 py-0"
      >
        <span :class="($vuetify.breakpoint.xs) ? 'text-h3' : 'text-h2'">{{ landingGreeting }}</span>
      </v-col>
    </v-row>

    <!-- Stat Cards -->
    <v-row
      v-if="!$vuetify.breakpoint.xs" 
      justify="space-around"
    >
      <!-- Live Campaigns -->
      <v-col
        @click="setVueLocal('campaignStatus', 'live')"
        cols="auto"
        sm="6"
        lg="3"
        class="my-0 py-0 m"
      >
        <theme-material-stats-card
          :value="String(liveCampaignsCount)"
          title="Live"
          icon="pat-live"
          :color="campaignStatus.toLowerCase() === 'live' ? 'primary' : 'sidebarColorLight'"
          style="cursor: pointer; height: 100px;"
        />
      </v-col>

      <!-- Pending Campaigns -->
      <v-col
        cols="auto"
        sm="6"
        lg="3"
        class="my-0 py-0 m"
        @click="setVueLocal('campaignStatus', 'pending')"
      >
        <theme-material-stats-card
          :value="String(pendingCampaignsCount)"
          title="Pending"
          icon="pat-pending"
          :color="campaignStatus.toLowerCase() === 'pending' ? 'tertiary' : 'sidebarColorLight'"
          style="cursor: pointer; height: 100px;"
        />
      </v-col>

      <!-- Completed Campaigns -->
      <v-col
        @click="setVueLocal('campaignStatus', 'completed')"
        class="my-0 py-0 m"
        cols="auto"
        sm="6"
        lg="3"
      >
        <theme-material-stats-card
          :value="String(completedCampaignsCount)"
          title="Completed"
          icon="pat-complete"
          :color="campaignStatus.toLowerCase() === 'completed' ? 'secondary' : 'sidebarColorLight'"
          style="cursor: pointer; height: 100px;"
        />
      </v-col>

      <!-- Total Campaigns -->
      <v-col
        cols="auto"
        sm="6"
        lg="3"
        class="my-0 py-0 m"
        @click="setVueLocal('campaignStatus', 'all')"
      >
        <theme-material-stats-card
          :value="String(liveCampaignsCount + pendingCampaignsCount + completedCampaignsCount)"
          title="Total"
          icon="mdi-all-inclusive"
          :color="campaignStatus.toLowerCase() === 'all' ? 'fourth' : 'sidebarColorLight'"
          class="text--red"
          style="cursor: pointer; height: 100px;"
        />
      </v-col>
    </v-row>

    <!-- Filter Campaigns -->
    <v-row
      class="ma-0 mt-5"
    >
      <!-- Search Input -->
      <v-col
        class="ma-0 pa-0 pr-3"
        cols="12"
        :sm="($store.state.Permissions.campaignAdd) ? 5 : 6"
      >
        <v-text-field
          autofocus
          v-model="searchCampaign"
          outlined
          label="Search"
          style="width: 100%"
          append-icon="mdi-magnify"
          
        >
        <template #append>
          <v-icon
            v-if="searchCampaign"
            color="primary"
            class="mr-2"
            @click="searchCampaign = ''"
          >
            mdi-close
          </v-icon>
        </template>
        </v-text-field>
      </v-col>
      
      <!-- Category Dropdown -->
      <v-col
        cols="4"
        sm="2"
        class="ma-0 pa-0 pr-3"
        style="min-width: 100px"
      >
        <v-select
          v-model="campaignStatus"
          @change="setVueLocal('campaignStatus', campaignStatus)"
          :items="campaignStatusOptions"
          item-text="text"
          item-value="value"
          label="Filter By"
          icon="mdi-filter"
          outlined
          class="text-capitalize"
        />
      </v-col>

      <!-- Sort By Dropdown -->
      <v-col
        cols="4"
        sm="2"
        class="ma-0 pa-0 pr-3"
        style="min-width: 100px"
      >
        <v-select
          v-model="campaignSortBy"
          @change="setVueLocal('campaignSortBy', campaignSortBy)"
          :items="campaignSortByOptions"
          item-text="text"
          item-value="value"
          label="Sort By"
          outlined
          icon="mdi-filter"
        />
      </v-col>

      <!-- Order By Dropdown -->
      <v-col
        cols="4"
        sm="2"
        class="ma-0 pa-0 pr-3"
        style="min-width: 100px"
      >
        <v-select
          v-model="campaignOrderBy"
          @change="setVueLocal('campaignOrderBy', campaignOrderBy)"
          :items="campaignOrderByOptions"
          item-text="text"
          item-value="value"
          label="Order By"
          icon="mdi-filter"
          outlined
        />
      </v-col>
      
      <!-- Add Campaign Button -->
      <v-col
        :cols="($store.state.Permissions.campaignAdd) ? 1 : 0"
        :class="(($vuetify.breakpoint.xs) ? 'ml-3 ' : '') + 'ma-0 pa-0 d-flex justify-end'"
      >
        <v-tooltip top>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              color="primary"
              @click="showCampaignCreateFormDialog = true"
              :disabled="!$store.state.Permissions.campaignAdd"
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Campaign</span>
        </v-tooltip>

      </v-col>
    </v-row>

    <!-- View Type Campaigns -->
    <v-row
      :style="{ paddingLeft: `${5}px` }"
      class="d-flex"
    >
      <!-- Grid view -->
      <v-col cols="auto" class="ma-0 mt-n6 px-1">
        <v-tooltip top>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="setVueLocal('campaignViewType', 'grid')"
              :color="(campaignViewType === 'grid') ? 'primary' : invertColor('#000', $vuetify.theme.dark)"
              :style="{ fontSize: `${30}px` }"
            >mdi-view-grid-outline</v-icon>
          </template>
          <span>Grid View</span>
        </v-tooltip>
      </v-col>

      <!-- List view -->
      <v-col cols="auto" class="ma-0 mt-n6 px-0">
        <v-tooltip top>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="setVueLocal('campaignViewType', 'list')"
              :color="(campaignViewType === 'list') ? 'primary' : invertColor('#000', $vuetify.theme.dark)"
              class="mt-n1"
              :style="{ fontSize: `${38}px` }"
            >mdi-view-list-outline</v-icon>
          </template>
          <span>List View</span>
        </v-tooltip>
      </v-col>
      <!-- Campaign Status View  -->
      <v-col cols="auto" class="ma-0 mt-n6 px-1" v-if="hasCampaignMilestoneViewPermission">
        <v-tooltip top>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="setVueLocal('campaignViewType', 'progress')"
              :color="(campaignViewType === 'progress') ? 'primary' : invertColor('#000', $vuetify.theme.dark)"
              :style="{ fontSize: `${28}px` }"
            >mdi-sitemap</v-icon>
          </template>
          <span>Campaign status</span>
        </v-tooltip>
      </v-col>
     
       <!-- Campaign status filters -->
       <div class="d-flex ml-auto">
         <!-- Campaign status Archived  -->
         <v-col cols="auto" class="ma-0 mt-n6 px-1">
          <v-tooltip top>
            <template v-slot:activator="{ attrs, on }">
              <v-hover v-slot="{ hover }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="toggleArchive()"
                  :color="includeArchived || hover ? 'primary' : invertColor('#000', $vuetify.theme.dark)"
                  :style="{ fontSize: `${28}px` }"
                >mdi-archive-search</v-icon>
              </v-hover>
            </template>
            <span>Include Archived</span>
          </v-tooltip>
        </v-col>
        <!-- Campaign status advanced filters  -->
        <v-col v-if="campaignViewType === 'progress'" cols="auto" class="ma-0 mt-n6 px-1">
          <v-tooltip top>
            <template v-slot:activator="{ attrs, on }">
              <v-hover v-slot="{ hover }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="showCampaignStatusFilters = !showCampaignStatusFilters"
                  :color="showCampaignStatusFilters || hover || selectedOwners.length !== 0  ? 'primary' : invertColor('#000', $vuetify.theme.dark)"
                  :style="{ fontSize: `${28}px` }"
                >mdi-filter</v-icon>
              </v-hover>
            </template>
            <span>Advanced filters</span>
          </v-tooltip>
        </v-col>
        <v-col v-if="campaignViewType === 'progress'" cols="auto" class="ma-0 mt-n6 px-1">
          <v-tooltip top>
            <template v-slot:activator="{ attrs, on }">
              <v-hover v-slot="{ hover }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="includeCompletedStatusGlobal = !includeCompletedStatusGlobal"
                  :color="includeCompletedStatusGlobal || hover ? 'primary' : invertColor('#000', $vuetify.theme.dark)"
                  :style="{ fontSize: `${28}px` }"
                >mdi-check</v-icon>
              </v-hover>
            </template>
            <span>Show all campaign statuses</span>
          </v-tooltip>
        </v-col>
        <!-- Campaign status View Apply profiles  -->
        <v-col v-if="campaignViewType === 'progress'" cols="auto" class="ma-0 mt-n6 px-1">
          <v-tooltip top>
            <template v-slot:activator="{ attrs, on }">
              <v-hover v-slot="{ hover }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="applyDProfileToBurst = true"
                  :color="hover ? 'primary' : invertColor('#000', $vuetify.theme.dark)"
                  :style="{ fontSize: `${28}px` }"
                >mdi-radar</v-icon>
              </v-hover>
            </template>
            <span>Edit Milestones</span>
          </v-tooltip>
        </v-col>
      </div>
    </v-row> 

    <!-- If no campaigns added yet -->
    <v-row 
      v-if="filteredCampaigns.length === 0 && campaignViewType !== 'progress'"
      justify="space-around"
    >
      <v-col cols="12">
        <span>Nothing available...</span>
      </v-col>
    </v-row>

    <!-- Grid View - Campaign Cards -->
    <v-row 
      v-else-if="campaignViewType === 'grid'"
      justify="space-around"
    >
      <v-col
        v-for="campaign in filteredCampaigns"
        :key="campaign.id"
        cols="auto"
        class="justify-center my-0 py-0"
      >
        <v-row>
          <CampaignMaterialCard
            :campaign="campaign"
            @clickCard="navigateToCampaignView"
            @clickAction="executeCampaignAction"
            :style="{ height: `${89}%` }"
          />
          <SOVSlider
            style="margin-left: 4px;"
            :campaign="campaign"
            :sliderGroups="sliderGroups"
            :key="campaign.id"
            :style="{ height: `${100}%` }"
            :interLinked="true"
            :lockSliderView="true"
            v-if="hasCampaignShareOfVoiceEdit"/>
        </v-row>
      </v-col>
    </v-row>

    <!-- List View -->
    <v-row
      v-else-if="campaignViewType === 'list'"
    >
      <CampaignsDataTable
        :headers="campaignsDataTableHeaders"
        :campaigns="filteredCampaigns"
        @clickRow="navigateToCampaignView"
        @clickAction="executeCampaignAction"
      />
    </v-row>

     <!-- Progress View  -->
     <v-row
      v-else-if="campaignViewType === 'progress'"
    >
      <CampaignsProgressView
        :campaign="filteredCampaigns"
        :campaignStatus="campaignStatus"
        :campaignSortBy="campaignSortBy"
        :campaignOrderBy="campaignOrderBy"
        :searchCampaign="searchCampaign"
        :showCampaignStatusFilters="showCampaignStatusFilters"
        @includeBursts="includeBursts"
        :includeBursts="includeBursts"
        @includeStatus="includeStatus"
        :includeStatus="includeStatus"
        @includeArchived="includeArchived"
        :includeArchived="includeArchived"
        @clickAction="executeCampaignAction"
        @checkSelectedOwner="checkSelectedOwner"
        :notificationCampaignId="notificationCampaignId"
        :notificationBurstId="notificationBurstId"
        :notificationIssueId="notificationIssueId"
        @filterToTotal="setVueLocal('campaignStatus', 'all')"
        :includeCompletedStatusGlobal="includeCompletedStatusGlobal"
        @showCampaignStatusFiltersChange="showCampaignStatusFilters = true"
      />
    </v-row>

    <!-- Campaign Create Dialog -->
    <v-dialog
      v-model="showCampaignCreateFormDialog"
      persistent
      width="700"
    >
      <campaign-create-form
        v-if="showCampaignCreateFormDialog"
        @getCampaignByIdFromChild="getAllCampaigns"
        @closeEditDialog="showCampaignCreateFormDialog = false"
      />
    </v-dialog>

    <!-- Campaign Edit Dialog -->
    <v-dialog
      v-model="showCampaignEditFormDialog"
      @click:outside="selectedCampaign = null;"
      width="500"
    >
      <v-card>
        <v-card-title class="white--text primary">
          Edit Campaign
        </v-card-title>

        <campaign-edit-form
          v-if="showCampaignEditFormDialog && selectedCampaign"
          :campaign="selectedCampaign"
          @getCampaignByIdFromChild="getAllCampaigns"
          @closeEditDialog="showCampaignEditFormDialog = false"
        />
      </v-card>
    </v-dialog>

    <!-- Campaign Assets Dialog -->
    <v-dialog
      v-model="showCampaignAssetsDialog"
      @click:outside="selectedCampaign = null;"
      width="500"
    >
      <campaign-assets-form
        v-if="showCampaignAssetsDialog && selectedCampaign"
        :campaign="selectedCampaign"
        @closeEditDialog="showCampaignAssetsDialog = false"
      />
    </v-dialog>

    <!-- Campaign Stakeholder Dialog -->
    <v-dialog
      v-model="showStakeholdersDialog"
      width="800"
    >
      <v-card>
        <v-card-title
          class="white--text primary"
        >
          Add stakeholder to campaign
        </v-card-title>
        <CampaignStakeholders
          v-if="showStakeholdersDialog && selectedCampaign"
          :campaignId="selectedCampaign.id"
        />
      </v-card>
    </v-dialog>

    <!-- Edit Burst Profile -->
    <v-dialog
      v-model="applyDProfileToBurst"
      @click:outside="applyDProfileToBurst = false"
      width="650"
    >
      <ProgressBurstProfiles 
        v-if="applyDProfileToBurst && campaignViewType === 'progress'"
        :campaigns="campaigns"
      />
    </v-dialog>
  </v-container>
</template>
<style>
.centerIt {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.item {
  display: flex;
  align-items: center;
  gap: 5px;
}


.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 22px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
</style>
<script>
  // Components
  import CampaignMaterialCard from '@/components/campaign/MaterialCard'
  import SOVSlider from '@/components/campaign/SOVSlider'
  import CampaignsDataTable from '@/components/campaigns/DataTable'
  import CampaignCreateForm from '@/components/campaign/CreateForm'
  import CampaignEditForm from '@/components/campaign/EditForm'
  import CampaignAssetsForm from '@/components/campaign/AssetsForm'
  import CampaignStakeholders from '@/components/campaign/Stakeholders'
  import CampaignsProgressView from '@/components/campaigns/ProgressView'
  import ProgressBurstProfiles from '@/components/campaigns/modals/ProgressBurstProfiles'

  // Services
  import CampaignController from '@/services/controllers/Campaign'
  import UserController from '@/services/controllers/User'

  // Mixins
  import collectionSort from '@/mixins/collection/sort'
  import collectionSearch from '@/mixins/collection/search'
  import helpersConcatText from '@/mixins/helpers/concatText'
  import helpersColor from '@/mixins/helpers/color'
  import localStorage from '@/mixins/local/storage'

  // External libraries
  import moment from 'moment'
  import { debounce } from 'lodash';

  export default {
    mixins: [
      helpersConcatText, 
      helpersColor,
      localStorage,
    ],

    components: {
      CampaignMaterialCard,
      SOVSlider,
      CampaignsDataTable,
      CampaignCreateForm,
      CampaignEditForm,
      CampaignAssetsForm,
      CampaignStakeholders,
      CampaignsProgressView,
      ProgressBurstProfiles,
    },

    filters: {
      moment: function (date) {
        return moment(date).format('DD MMM YYYY')
      },
    },

    data: () => ({
      // Current User
      currentUser: null,

      // List of campaigns
      campaigns: [],

      // Users current selected campaign for dialogs
      selectedCampaign: null,

      // Campaign Management Dialogs
      showCampaignCreateFormDialog: false,
      showCampaignEditFormDialog: false,
      showStakeholdersDialog: false,
      showCampaignAssetsDialog: false,

      // list / grid view
      campaignViewType: 'grid',
      
      // Search input
      searchCampaign: '',
      
      // Filter By
      campaignStatus: 'all',
      campaignStatusOptions: [
        { text: 'Live', value: 'live' },
        { text: 'Pending', value: 'pending' },
        { text: 'Completed', value: 'completed' },
        { text: 'Total', value: 'all' },
      ],

      // Sort By
      campaignSortBy: 'name',
      campaignSortByOptions: [
        { text: 'Name', value: 'name' },
        { text: 'kNumber', value: 'kNumber' },
        { text: 'Start Date', value: 'startDate' },
        { text: 'End Date', value: 'endDate' },
      ],

      // Order By
      campaignOrderBy: 'asc',
      campaignOrderByOptions: [
        { text: 'Ascending', value: 'asc' },
        { text: 'Descending', value: 'desc' },
      ],

      // Profile/Burst management
      applyDProfileToBurst: false,
      showCampaignStatusFilters: false,
      includeBursts: true,
      includeStatus: false,
      includeArchived: false,
      campaignsWithMilestones: [],
      selectedOwners: [],
      // Control variable to handle notifications hooks
      notificationCampaignId: null,
      notificationBurstId: null,
      notificationIssueId: null,
      includeCompletedStatusGlobal: true,

      // Search Parameters
      searchParams: [
        { name: 'name', weight: 2 },
        { name: 'campaignStakeholders.stakeholder.name', weight: 0.5 },
        { name: 'deliveryType.name', weight: 0.3 },
        { name: 'kNumber', weight: 3 },
        { name: 'startDate', weight: 0.01 },
        { name: 'endDate', weight: 0.01 },
        { name: 'campaignBursts.name', weight: 0.5 },
        { name: 'campaignBursts.campaignBurstScreens.screen.n', weight: 0.75 },
        { name: 'campaignBursts.campaignBurstScreens.screen.mo.n', weight: 1},
        { name: 'campaignBursts.campaignBurstScreens.triggerList', weight: 0.75 },
        { name: 'description', weight: 1 },
        { name: 'campaignBursts.deliveryType.name', weight: 1 },
        { name: 'campaignBursts.owner.contactCard.firstName', weight: 0.75 },
        { name: 'campaignBursts.owner.contactCard.lastName', weight: 0.5 },
      ],

      // Search Threshold
      searchThreshold: 0.2,
      sliderGroups: [],
    }),
    mounted() {
      // GET UI from vuex
      // check if props (campaignId, campaignBurstId, issueId) exist
      // if they do, then assign them to the local variables
      // if they don't, then assign null to the local variables
      const vuexUI = this.$store.state.UI
      console.log("vuexUI", this.$store)
      this.notificationCampaignId = vuexUI.campaignId ? parseInt(vuexUI.campaignId) : null
      this.notificationBurstId = vuexUI.campaignBurstId ? parseInt(vuexUI.campaignBurstId) : null
      this.notificationIssueId = vuexUI.issueId ? parseInt(vuexUI.issueId) : null

      // get searchCampaign from local storage and assign to searchCampaign
      if(this.getVueLocal('searchCampaign') !== null)
        this.searchCampaign = this.getVueLocal('searchCampaign')

    },
    created() {
      // If local storage variables are set, then map them to the local variables
      this.initVueLocal(['campaignViewType', 'campaignStatus', 'campaignSortBy', 'campaignOrderBy'])

      // Get current logged in user
      this.getUser()
      if(this.campaignViewType.includes('list') || this.campaignViewType.includes('grid')) 
        this.getAllCampaigns()

      this.$nextTick()
      // Global function
      this.$root.$on('executeCampaignActionFromChild', (action, item) => {
        this.selectedCampaign = selectedCampaign

        switch (action) {
          case 'showStakeholdersDialog':
            this.showStakeholdersDialog = true
            
            break;

          case 'showCampaignEditFormDialog':
            this.showCampaignEditFormDialog = true
            
            break;

          case 'showCampaignAssetsDialog':
            this.showCampaignAssetsDialog = true
            
            break;
        
          default:
            break;
        }
      this.$nextTick()
      })  
    },
    // destroy the event listener
    beforeDestroy() {
      console.log("executeCampaignActionFromChild destroyed")
      this.$root.$off('executeCampaignActionFromChild')
    },

    computed: {
       // Function to check is user has campaignMilestoneView permission
       hasCampaignMilestoneViewPermission() {
        return this.$store.state.Permissions.campaignMilestoneView
      },

      hasCampaignShareOfVoiceEdit() {
        return this.$store.state.Permissions.campaignShareOfVoice
      },



      // Purpose: return filtered and sorted campaigns
      filteredCampaigns() {
        if(this.campaigns && this.campaigns.length > 0) {
          switch (this.campaignStatus) {
            case 'live':
              return collectionSort(collectionSearch(this.liveCampaigns, this.searchParams, this.searchCampaign, this.searchThreshold), this.campaignSortBy, this.campaignOrderBy)

            case 'pending':
              return collectionSort(collectionSearch(this.pendingCampaigns, this.searchParams, this.searchCampaign, this.searchThreshold), this.campaignSortBy, this.campaignOrderBy)

            case 'completed':
              return collectionSort(collectionSearch(this.completedCampaigns, this.searchParams, this.searchCampaign, this.searchThreshold), this.campaignSortBy, this.campaignOrderBy)

            default:
              return collectionSort(collectionSearch(this.campaigns, this.searchParams, this.searchCampaign, this.searchThreshold), this.campaignSortBy, this.campaignOrderBy)
          }
        }

        return []
      },

      liveCampaigns() {
        if(this.campaigns && this.campaigns.length > 0) {
          return this.campaigns.filter(campaign => campaign.status.type === 'live');
        }

        return []
      },

      liveCampaignsCount() {
        if(this.campaigns && this.campaigns.length > 0) {
          return collectionSort(collectionSearch(this.liveCampaigns, this.searchParams, this.searchCampaign, this.searchThreshold), this.campaignSortBy, this.campaignOrderBy).length
        }

        return 0
      },

      pendingCampaigns() {
        if(this.campaigns && this.campaigns.length > 0) {
          return this.campaigns.filter(campaign => campaign.status.type === 'pending');
        }
        return []
      },

      pendingCampaignsCount() {
        if(this.campaigns && this.campaigns.length > 0) {
          return collectionSort(collectionSearch(this.pendingCampaigns, this.searchParams, this.searchCampaign, this.searchThreshold), this.campaignSortBy, this.campaignOrderBy).length
        }
        return 0
      },

      completedCampaigns() {
        if(this.campaigns && this.campaigns.length > 0) {
          return this.campaigns.filter(campaign => campaign.status.type === 'completed');
        }
        return []
      },

      completedCampaignsCount() {
        if(this.campaigns && this.campaigns.length > 0) {
          return collectionSort(collectionSearch(this.completedCampaigns, this.searchParams, this.searchCampaign, this.searchThreshold), this.campaignSortBy, this.campaignOrderBy).length
        }
        return 0
      },

      // Purpose: Return list view headers
      campaignsDataTableHeaders() {
        // Default headers
        let headers =  [
          { text: 'Name', value: 'name', sortable: false },
          { text: 'Description', value: 'description', sortable: false },
          { text: 'K Number', value: 'kNumber', sortable: false },
          { text: 'Start', value: 'startDate', sortable: false },
          { text: 'End', value: 'endDate', sortable: false },
        ]

        // Permission based headers
        if(this.$store.state.Permissions.campaignEdit || this.$store.state.Permissions.usersAndStakeholders) {
          headers.push( { text: 'Actions', value: 'actions', sortable: false } )
        }

        return headers
      },

      // Purpose: Return greeting message
      landingGreeting() {
        const today = new Date()

        if(this.currentUser) {
          if (today.getDay() == 5) {
            return `Happy Friday, ${this.currentUser.contactCard.firstName}!`
          }
          else {
            return `Welcome back, ${this.currentUser.contactCard.firstName}!`
          }
        }

        return `Welcome back!`
      }
    },

    watch: {
      includeArchived: function(newValue, oldValue) {
        this.getAllCampaigns()
      },
      campaignViewType: function(newValue, oldValue) {
        switch(newValue)
        {
          case 'grid':
            break;
          case 'list':
            this.includeBursts = true;
            this.includeStatus = false;
            break;
          case 'progress':
            this.includeBursts = true;
            this.includeStatus = true;
            this.getAllCampaignsWithMilestones()
            break;
        }
      },
      sliderGroups: {
          handler: function (newValue, oldValue) {
            this.debounceSaveLinkedMetadata();
          },
          deep: true,
      },
      '$store.state.UI.issueTaskModal' (newValue, oldValue) {
        // if the new value is not null, then open the issue modal
        if(newValue === true) {
          // Check campaignViewType === progress, then emit snackbar 
          if(this.campaignViewType !== 'progress') {
            this.$root.$emit("snackbarWarning", "Please move to \"Campaign Status\" campaign view and try again.")
            return
          }
        }
      }, 
      searchCampaign: function(newValue, oldValue) {
        // store new value in local storage
        this.setVueLocal('searchCampaign', newValue)
        // get the searchCampaign from local storage and console log it
      },
    },
    provide: function () {
      return {
        // Modals
        closeApplyDProfileToBurstDialog: this.closeApplyDProfileToBurstDialog,
        navigateToCampaignView: this.navigateToCampaignView,
      }
    },

    methods: {
      checkSelectedOwner(newOwner) {
        if(newOwner) {
          this.selectedOwners.push(newOwner)
        }
        else {
          this.selectedOwners = []
        }
      },
      toggleArchive() {
        this.includeArchived = !this.includeArchived
        // Success snackbar
        this.$root.$emit("snackbarSuccess", "Archived campaigns are now " + (this.includeArchived ? "included" : "excluded") + " from the list.")
      },
      // Close applyDProfileToBurst#dialog
      closeApplyDProfileToBurstDialog() {
        this.applyDProfileToBurst = false
      },
      // Get all campaigns with milestones
      async getAllCampaignsWithMilestones() {
        this.includeStatus = true
        // Get all campaigns with milestones
        await CampaignController.getAllCampaigns(this.includeBursts,this.includeStatus,this.includeArchived)
        .then((res)=>{  
          this.campaigns = res.data
          this.campaignsWithMilestones = res.data
          this.addCampaignStatus()
          this.$nextTick()
        }).catch((err) => {
          this.$root.$emit("snackbarError", err.response.data.message)
        })
      },

      // Purpose: Function to get all the campaigns available to the user without milestones
      async getAllCampaigns() {
        // Get all campaigns
        const { data = null } = await CampaignController.getAllCampaigns(this.includeBursts,this.includeStatus,this.includeArchived)
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

        // If the response contains valid data then append
        if(data) {
          
          this.campaignsWithMilestones = []
          this.campaigns = data
          this.$nextTick()
          this.addCampaignStatus()
          await this.getLinkedCampaigns()
        }
        else {
          this.campaigns = []
        }
      },

      async getLinkedCampaigns() {
        try {
          const res = await CampaignController.getLinkedCampaigns();
          // get sliders data from metaData
          res.data.forEach(linkedGroup => {
            let groupId = linkedGroup.contentLinkGroup.id;
            if(linkedGroup.contentLinkGroup.metaData == undefined)
              linkedGroup.contentLinkGroup.metaData = "{}";
            let metaData = JSON.parse(linkedGroup.contentLinkGroup.metaData);
            
            if(metaData.sliders == undefined)
              metaData.sliders = [];
            // do all campaigns in the linkedGroup have an item in metaData.sliders?
            linkedGroup.campaigns.forEach(campaign => {
              let found = false;
              metaData.sliders.forEach(slider => {
                slider.isDirty = false;
                if(slider.campaignId == campaign.id) {
                  found = true;
                }
              });
              if(!found) {
                metaData.sliders.push({ isDirty: false, campaignId: campaign.id, value: 0});
              }
              // assign the groupId to this.campaigns
              this.campaigns.forEach(c => {
                if(c.id == campaign.id) {
                  c.groupId = groupId;
                }
              });
            });
            this.sliderGroups.push({groupId: groupId, sliders: metaData.sliders});
          });
        } catch (err) {
          console.log(err);
        } finally {
          
        }
      },

      async saveLinkedMetadata(sliderGroup) {
        // Your save logic here
        await CampaignController.saveLinkedMetadata(sliderGroup);
      },
      debounceSaveLinkedMetadata: debounce(async function(sliderGroup) {
        this.sliderGroups.forEach(async newGroup => {
          if(newGroup.shouldSave)
          {
            // there doesn't seem a point to comparing the newValue and oldValue as they seem  to be the same
            let contentLinkGroupUpdate = {
              id: newGroup.groupId,
              param: "sliders",
              metaData: JSON.stringify(newGroup.sliders)
            }
            await this.saveLinkedMetadata(contentLinkGroupUpdate);
            newGroup.shouldSave = false;
          }
        });
      }, 500), // Adjust the debounce time as needed

      // Purpose: Function to split campaign by status
      addCampaignStatus() {
                this.campaigns.map(campaign => {
                // Live Campaigns
                if (moment().isBetween(campaign.startDate, moment(campaign.endDate).add(1, 'days'))) {
                    campaign.status = { type: 'live', color: 'primary' }
                }
                // Pending Campaigns
                else if (moment(new Date()).isBefore(campaign.startDate) || campaign.startDate === null ||  campaign.endDate === null) {
                    campaign.status = { type: 'pending', color: 'tertiary' }
                }
                // Finished Campaigns
                else if (moment(campaign.endDate).isBefore(new Date())) {
                    campaign.status = { type: 'completed', color: 'secondary' }
                } 

                return campaign
                })
            },

      // Purpose: Function to get the current logged in user
      async getUser() {
        const res = await UserController.whoAmI().catch(err => {
          // Catch and display snackbar error message 
          this.$root.$emit('snackbarError', ''+ err.response.data.message)
        })

        if(res) {
          this.currentUser = res.data
        }
      },

      // Purpose: Function to navigate to the selected campaign view
      navigateToCampaignView(cid) {
        this.$router.push({
          name: 'Campaign',
          query: { cid: cid, tab: this.$store.getters.getCampaignTab },
        })
      },

      // Purpose: Execute an actions function on selected campaign
      executeCampaignAction(selectedCampaign, action) {
        this.selectedCampaign = selectedCampaign

        switch (action) {
          case 'showStakeholdersDialog':
            this.showStakeholdersDialog = true
            
            break;

          case 'showCampaignEditFormDialog':
            this.showCampaignEditFormDialog = true
            
            break;

          case 'showCampaignAssetsDialog':
            this.showCampaignAssetsDialog = true
            
            break;
        
          default:
            break;
        }
      },
    },
  }
</script>
